import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache, type NormalizedCacheObject, Observable } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { AuthErrors } from "@technis/shared";

import { type OperationDefinitionNode } from "graphql";

import { omitDeep } from "@utils/generic";

import { getAuthCookie } from "@common/helpers/cookie";

import { logout, saveToken } from "@redux/auth/auth.slice";

import { store } from "@store/store";

import { fetchGQL } from "./fetch";

type Headers = {
  headers: {
    [key: string]: string;
    authorization: string;
  };
};

const getCurrentAuthorization = (token?: string): string => `Bearer ${token || getAuthCookie()}`;
const setHeaderWithToken = (headers: Record<string, string>, token?: string): Headers => ({
  headers: {
    ...headers,
    authorization: getCurrentAuthorization(token),
  },
});

const authLink = setContext((_, { headers }) => setHeaderWithToken(headers));

const autoRenewTokenState: { error?: string; renewing: boolean } = {
  renewing: false,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const renewTokenAndSave = (): Promise<string | void> =>
  autoRenewTokenState.renewing
    ? new Promise<string | void>((resolve, reject) => {
        let index = 1;
        const interval = setInterval(() => {
          const clear = (): void => clearInterval(interval);
          if (index > 10) {
            clear();
            return reject("Token refresh timed out.");
          }
          if (!autoRenewTokenState.renewing) {
            if (autoRenewTokenState.error) {
              clear();
              return reject(autoRenewTokenState.error);
            }
            clear();
            return resolve();
          }
          index++;
        }, 500);
      })
    : Promise.resolve().then(() => {
        autoRenewTokenState.renewing = true;
        return fetchGQL<{ renew: string }>("query { renew }", getAuthCookie())
          .then((response) => {
            console.log("RENEWED TOKEN", response);
            const { renew: newToken } = response;
            if (!newToken) {
              throw new Error(AuthErrors.INVALID_TOKEN);
            }
            store.dispatch(saveToken(newToken));
            autoRenewTokenState.renewing = false;
            return newToken;
          })
          .catch((error: Error) => {
            autoRenewTokenState.renewing = false;
            autoRenewTokenState.error = error.message;
            throw error;
          });
      });

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    const isTokenExpired = graphQLErrors.some(({ message }) => message === AuthErrors.TOKEN_EXPIRED);

    const isTokenInvalid = graphQLErrors.some(({ message }) => message === AuthErrors.INVALID_TOKEN);

    if (isTokenExpired) {
      const previousHeaders = operation.getContext().headers;
      return new Observable((observer) => {
        renewTokenAndSave()
          .then((newToken) => {
            operation.setContext(setHeaderWithToken(previousHeaders, newToken || getAuthCookie()));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            forward(operation).subscribe(subscriber);
          })
          .catch((error: Error) => {
            observer.error(error);
          });
      });
    }

    if (isTokenInvalid) {
      store.dispatch(logout());
    }
  }
  if (networkError) {
    console.log(graphQLErrors, networkError, operation);
    console.log(`[Network error]: ${networkError}`);
    // noinspection JSUnusedAssignment
    networkError = undefined;
  }
});

const httpLink = createHttpLink({
  uri: `${process.env.APPLICATION_API_URL}/graphql`,
});

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (((operation.query.definitions[0] || {}) as OperationDefinitionNode).operation === "mutation") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    operation.variables = omitDeep(operation.variables, "__typename");
  }

  return forward(operation);
});

const link = from([errorLink, authLink, omitTypenameLink, httpLink]);

export const api: { client: ApolloClient<NormalizedCacheObject> | undefined } = {
  client: undefined,
};

export const initApolloClient = async (): Promise<void> => {
  if (!api.client) {
    api.client = new ApolloClient({
      link,
      cache: new InMemoryCache({
        addTypename: true,
      }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
          notifyOnNetworkStatusChange: true,
        },
      },
    });
  }
};
