import { getAuthCookie } from "@common/helpers/cookie";

export const fetchGQL = <T>(query: string, token = getAuthCookie()): Promise<T> =>
  fetch(`${process.env.APPLICATION_API_URL}/graphql`, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({ query }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(({ data }) => data)
    .catch((error: Error) => {
      throw error;
    });
